import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons';
import { Button, IconButton, Grid, AppBar, Toolbar, useMediaQuery } from '@material-ui/core';
import { navigate, Link } from '@reach/router';
import { useResizeDetector } from 'react-resize-detector';
import Logo from '../../assets/images/logo1-preview.png';
import { DrawerComponent } from '../../components/Drawer';
import { useLocation } from "@reach/router";
import { useTheme } from '@material-ui/core/styles';
import './animation.css';

const TopBanner = ({ classes, accessToken, user, headerClasses }) => {
  const { pathname } = useLocation();
  const { width, ref } = useResizeDetector();
  const [mobile, setMobile] = useState(false);
  const [sticky, setSticky] = useState(false);
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {

    const isMobile = () => {
      if (width < 510) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    isMobile();
  }, [width, sticky]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    //Al iniciar consulta
    const handleScrollEvent = () => {
      handleScroll();
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  });

  const handleScroll = () => {
    if (window.pageYOffset > 700) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    // <AppBar className={classes.headNavbar}>
    <AppBar>
      
      <Toolbar style={{ display: 'none' }}>
        <div className={classes.topBannerRoot}>
          <div
            className={
              sticky
                ? classes.topBannerContainerTrue
                : classes.topBannerContainer
            }
          >
            <div
              className={
                sticky
                  ? classes.logoAndinitoContainerTrue
                  : classes.logoAndinitoContainer
              }
            >
              <div className={sticky && 'fade-right active'}>
              {/* <Link to="/">
                <img
                  className={sticky ? classes.imageLogoTrue : classes.imageLogo}
                  style={{width: '90%', height: '70px'}}
                  src={Logo}
                  alt="main-logo"
                  loading="lazy"
                  onClick={ () => handleScrollTop()}
                />
                </Link> */}
              </div>
            </div>
            {/* <div className={classes.topBannerWrapper}> */}
            {/* <p> +58 424 7228323 Soporte </p>
              <p> +58 424 7649152 WhastApp </p>
              <p> info@andinito.com</p> */}
            <DrawerComponent
              accessToken={accessToken}
              user={user}
              classes={headerClasses}
              sticky={sticky}
            />
            {/* </div> */}
            {!sticky ? (
              <div className={classes.topBannerWrapper} ref={ref}>
                {!sticky && responsive  ? (
                  <></>
                ) : ""}
                {/* <IconButton>
                  <a
                    href="https://twitter.com/AndinitoOficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </IconButton>
                <IconButton>
                  <a
                    href="https://bit.ly/3bJ60cL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </IconButton>
                <IconButton>
                  <a
                    href="https://t.me/Loteriaelandinito"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTelegram} />
                  </a>
                </IconButton>

                <IconButton>
                  <a
                    href="https://www.facebook.com/elandinito.oficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </IconButton>

                <IconButton>
                  <a
                    href="https://instagram.com/elandinito.oficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </IconButton>

                <IconButton>
                  <a
                    href="https://www.youtube.com/channel/UCRIO9PMSmwopUPFmIB4cVlQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </IconButton> */}
              </div>
            ) : (
              ''
            )}
          </div>
          {/* {mobile ? (
            <>
              <div
                className={classes.logoAndinitoContainer}
              >
                <div className={sticky && 'fade-right active'}>
                  <img
                    className={classes.imageLogo}
                    src={Logo}
                    alt="main-logo"
                    loading="lazy"
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          )} */}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBanner;
