import { createMuiTheme } from '@material-ui/core';

const buttonSizes = {
  widhtSm: '91px',
  heightSm: '59px',
  widhtMd: '163px',
  heightMd: '44px',
  widhtLg: '195px',
  heightLg: '44px',
  widhtXl: '240px',
  heightXl: '44px',
};

export const themeProps = createMuiTheme({
  typography: {
    fontFamily: ['Inter'],
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontDisplay: 'swap'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          '& body': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiPaper: {
      elevation1: {
        // boxShadow: 'unset',
      },
    },
    MuiButton: {
      containedPrimary: {
        background:
          'linear-gradient(90deg, rgba(0,29,76,1) 0%, rgba(2,51,131,1) 50%, rgba(0,29,76,1) 100%)',
        borderRadius: 10,
        textTransform: 'none',
        fontSize: 18,
      },
      containedSecondary: {
        background:
          'linear-gradient(90deg, rgba(255,8,0,1) 0%, rgba(255,20,0,1) 50%, rgba(255,8,0,1) 100%)',
        borderRadius: 10,
        textTransform: 'none',
        fontSize: 18,
      },
      contained: {
        '&.Mui-disabled': {
          color: 'rgb(194 194 194)',
        },
      },
    },
    MuiList: {
      root: {
        maxHeight: 200,
      },
    },
  },
  palette: {
    primary: {
      main: '#05051e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EA802E',
      contrastText: '#fff',
    },
  },

  props: {
    colors: {
      primary: '#132C6F',
      secondary: '#EA802E',
      warning: '#F44336',
      secondaryHover: '#c4651d',
      yellow: '#FCE01B',
      lightYellow: '#F7E287',
      lightBlue: '#8DABDE',
      white: '#fff',
      black: '#141413',
    },
    button: {
      sm: {
        width: buttonSizes.widhtSm,
        height: buttonSizes.heightSm,
        textAlign: 'center',
        fontSize: '18px',
        borderRadius: '10px',
        textTransform: 'none',
      },
      md: {
        width: buttonSizes.widhtMd,
        height: buttonSizes.heightMd,
        textAlign: 'center',
      },
      lg: {
        width: buttonSizes.widhtLg,
        height: buttonSizes.heightLg,
        textAlign: 'center',
      },
      xl: {
        width: buttonSizes.widhtXl,
        height: buttonSizes.heightXl,
        textAlign: 'center',
      },
      widthAuto: {
        height: buttonSizes.heightMd,
        textAlign: 'center',
      },
    },
  },
});
