import React from 'react';
import { Link, navigate } from '@reach/router';
import {
  Divider,
  Drawer,
  IconButton,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { headerStyle } from '../Header/styles';
import { useStyle } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const DrawerComponent = ({ accessToken, user, classes, sticky }) => {
  const [open, setOpen] = React.useState(false);
  const logout = () => {
    window.location.replace('/');

    localStorage.clear();
  };

  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRedirect = () => {
    localStorage.removeItem('url');
  };

  return (
    <>
      {/* {responsive && (
        <div>
          <Link to="/" onClick={(e) => {handleRedirect()}} style={{ fontWeight: '800', color: 'white' }}>
            Volver
          </Link>
        </div>
      )} */}
      {responsive && (
        <Drawer
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes?.drawerPaper,
          }}
        >
          <div style={{ marginTop: 15 }}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon className={classes?.drawerIcon} />
            </IconButton>
          </div>
          <nav>
            <ul className={classes?.drawerList}>
              {accessToken ? (
                <>
                  <li>
                    <Link to="/">Inicio</Link>
                  </li>
                  {/* <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/perfil/mis-cartones">Mis cartones</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/compra-cartones">Compra un cartón</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/">Instrucciones</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/acerca-de-nosotros">Acerca de nosotros</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/contacto">Contacto</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li> <Link to="/encuentranos">Encuéntranos</Link></li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <div id="logout" onClick={() => logout()}>
                      Cerrar Sesión
                    </div>
                  </li> */}
                </>
              ) : (
                <>
                  {/* <Link to="/" onClick={(e) => {handleRedirect()}} style={{ fontWeight: '800' }}>
                    Volver
                  </Link> */}

                  {/* <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/registro">Registro</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/">Instrucciones</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/acerca-de-nosotros">Acerca de nosotros</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li>
                    <Link to="/contacto">Contacto</Link>
                  </li>
                  <Divider className={classes?.drawerDivider} />
                  <li><Link to="/encuentranos">Encuéntranos</Link></li> */}
                </>
              )}
            </ul>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignContent: 'space-between',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: 'white',
              }}
            >
              {/* <Typography>
                {' '}
                <b>Encuentranos</b>{' '}
              </Typography>
              <Typography> Soporte: +58 424 7228323 </Typography>
              <Typography> WhastApp: +58 424 7649152 </Typography>
              <Typography> info@andinito.com</Typography> */}
            </div>
          </nav>
        </Drawer>
      )}
    </>
  );
};
