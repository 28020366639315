export const headerStyle = ({ breakpoints, props }) => {
  const hoverTransition = {
    '&:after': {
      bottom: 0,
      content: '""',
      display: 'block',
      height: '2px',
      left: '50%',
      position: 'absolute',
      background: '#fff',
      transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      width: 0,
    },

    '&:hover:after': {
      width: '50%',
      left: '23%',
    },
  };

  return {
    root: {
      flexGrow: 1,
    },
    smallHeader: {
      height: '29px',
      backgroundColor: props.colors.secondary,
      padding: '.5rem 0',
    },
    smallHeaderRight: {
      width: '50%',
      marginLeft: 'auto',

      [breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    navWrapper: {
      padding: '0 30px',

      [breakpoints.down('500')]: {
        padding: '0',
      },
    },
    smallHeaderList: {
      display: 'flex',
      margin: 0,
      padding: 0,
      listStyle: 'none',
      justifyContent: 'flex-end',

      '& li': {
        padding: '0 30px',
        fontSize: '.8125rem',

        '& a': {
          color: props.colors.white,
          textDecoration: 'none',
        },
      },

      '& a': {
        color: '#fff',
        textDecoration: 'none',
      },
    },
    mainHeader: {
      height: '98px',
      backgroundColor: '#4D5DF4',
      boxShadow: 'none',
    },
    mainHeaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: 'inherit',
    },
    mainHeaderRight: {
      marginLeft: 'auto',
    },
    mainNavWrapper: {
      padding: '0 30px',
    },
    mainHeaderList: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      listStyle: 'none',

      '& li': {
        fontSize: '.95rem',

        '& a,& div': {
          padding: '8px 25px',
          position: 'relative',
          ...hoverTransition,

          [breakpoints.down('1024')]: {
            padding: '8px 20px',
          },
        },
      },

      '& a': {
        color: '#fff',
        textDecoration: 'none',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
        },

        ...hoverTransition,
      },
    },
    balance: {
      fontSize: '.95rem',
      padding: '8px 40px',
    },
    name: {
      fontSize: '.95rem',
      padding: '8px 40px',
    },
    logo: {
      padding: '0 0 0 20px',

      display: 'flex',

      '& img': {
        width: '84px',
        height: '86px',
        cursor: 'pointer',
      },
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: 'auto',
      marginRight: '20px',
      fontSize: '30px',

      position: 'absolute',
      top: (props) => (props?.pathname === '/' ? '38px' : 'unset'),
      right: '15vh',
      
    },

    menuButtonTrue: {
      marginLeft: 'auto',
      marginRight: '20px',
      fontSize: '30px',

      position: 'absolute',
      top: (props) => (props?.pathname === '/' ? '38px' : 'unset'),
      right: '-4vh',
      
    },
    drawerPaper: {
      width: '75vw',
      backgroundColor: 'rgba(77,93,244, 0.93)',
    },
    drawerIcon: {
      color: '#fff',
    },
    drawerList: {
      listStyle: 'none',
      paddingRight: '30px',
      textAlign: 'right',

      '& li, div': {
        margin: '30px 0',
      },

      '& a, div, & #logout, #balance': {
        fontSize: '20px',
        color: '#fff',
        textDecoration: 'none',
      },
    },
    drawerDivider: {
      backgroundColor: '#fff',
    },
    countdown: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 20,
      fontSize: 16
    }
  };
};
