import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import Logo from '../../assets/images/logo1-preview.png';

const LazyLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loader}>
      <img
        src={Logo}
        loading="eager"
        className={classes.img}
        alt="logo-rotate"
      />
    </Box>
  );
};

export default LazyLoader;

export const useStyles = makeStyles(() => {
  return {
    loader: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    img: {
      width: 160,
      animation: '$rotation 5s infinite linear',
    },
    '@keyframes rotation': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(359deg)',
      },
    },
  };
});
