import axios from '../config/axios';

const DRAWS = '/draws';

// Method uses to fecht all draws
 const fetchDraws = async (skip = 0, limit = 0, tickets = false) => {
  try {
    const params = {
      skip,
      limit,
    };

    if (tickets) {
      params['tickets'] = tickets;
    }

    const { data } = await axios.get(`${DRAWS}`, {
      params,
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

const findByPublishWinners = async () => {
  const data = await axios.get(`${DRAWS}/publish-winners`);
  return data.data;
};

// Method used to get the current draw active
const fetchDrawActive = async () => {
  try {
    const { data } = await axios.get(`${DRAWS}/active`);
    
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export default {
  fetchDrawActive,
  fetchDraws,
  findByPublishWinners
}