import axios from '../config/axios';
const TVDRAWS = 'tv-draws';

export const getDrawsTv = async () => {
  try {
    const response = await axios.get(
      `${TVDRAWS}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching draws:', error);
    throw error; 
  }
};


export const getDrawsTvBy = async (queryParams) => {
    try {
      const response = await axios.get(
        `${TVDRAWS}/get-by`,
        { params: queryParams } 
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching draws:', error);
      throw error; 
    }
  };