import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import "./index.scss";


window.onbeforeunload = () => {
  localStorage.removeItem('persist:kino');
  localStorage.clear();
  persistStore(store).purge();
};
window.addEventListener('beforeunload', () => {
  localStorage.removeItem('persist:kino');
  localStorage.clear();
  persistStore(store).purge()
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
