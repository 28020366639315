import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  user: null,
  loading: false,
  statusCode: null,
  message: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    authReducer: (state, action) => {
      const { accessToken, user } = action.payload;

      state.user = user;
      state.accessToken = accessToken;
    },
    updateAuthUser: (state, action) => {
      const { firstName, lastName, email, phoneNumber, gender, county, birthdate,dni,receiveNotificationEmail } = action.payload;
      state.user.firstName = firstName;
      state.user.lastName = lastName;
      state.user.email = email;
      state.user.phoneNumber = phoneNumber;
      state.user.state = action.payload.state;
      state.user.county = county;
      state.user.gender = gender;
      state.user.dni = dni;
      state.user.receiveNotificationEmail = receiveNotificationEmail
      state.user.birthdate = birthdate
    },
    updateUserBalance: (state, action) => {
      const { rechargeType, balance } = action.payload;

      if (rechargeType === 'add') {
        state.user.balance = balance;
      }

      if (rechargeType === 'remove') {
        state.user.balance = balance;
      }
    },
  },
});

export const {
  updateUserBalance,
  updateAuthUser,
  authReducer,
} = loginSlice.actions;

export const loginSelector = ({ login }) => ({
  loading: login.loading,
  message: login.message,
  statusCode: login.statusCode,
});

export const accessTokenSelector = ({ login }) => login.accessToken;

export const userRoleSelector = ({ login }) => login.user?.role;

export const userSelector = ({ login }) => login.user;

export default loginSlice.reducer;
