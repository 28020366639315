import React from 'react';

import { Helmet } from 'react-helmet';

export default function Head({ title = '' }) {
  return (
    <Helmet>
      <title>{title} | LottoQuiz</title>
      <meta  name="description" content="Juega a LottoQuiz y gana grandes premios." />
    </Helmet>
  );
}
