import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from './slices/loginSlice';
import ticketSlice from './slices/ticketSlice';
import userSlice from './slices/userSlice';

const rootReducer = combineReducers({
  login: loginReducer,
  ticketList: ticketSlice,
  user: userSlice,
});

export default rootReducer;
