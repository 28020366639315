import React, { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Routes from './routes/Routes';
import "./index.scss";
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import { themeProps } from './utils/ThemeProps';


const theme = themeProps;

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

// const errorBoundary = () => {
//   return (
//     <div>
//       Sorry we have problems!
//       <img src="./src/assets/gifs/ErrorBoundary.gif" />
//     </div>
//   );
// };

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
