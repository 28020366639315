export const styles = ({ breakpoints, props }) => ({
  root: {
    margin: '13rem 0',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.7rem',
    margin: '1.5rem 0 0.6rem 0',
  },
  contentWrapper: {
    padding: '2rem 4rem',

    [breakpoints.down('md')]: {
      padding: '0',
    },
  },
  field: {
    padding: '0.5rem 0rem',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'space-between',

    [breakpoints.down('md')]: {
      marginTop: '15px',
      flexDirection: 'column',
    },
  },
  fieldTitle: {
    width: '50%',
    textAlign: 'start',
    fontWeight: 700,

    [breakpoints.down('md')]: {
      textAlign: 'center',
      width: '100%',
    },

    [breakpoints.up('md')]: {
      // textAlign: 'right',
    },
  },
  fieldDescription: {
    width: '50%',

    [breakpoints.down('md')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  buttonWrapper: {
    height: '200px',
    padding: '1rem 0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& button': props.button.lg,
  },
});
