import React from 'react';
import Head from '../../components/SEO';

import { useDispatch } from 'react-redux';
import { resetTicketsState } from '../../store/reducers/slices/ticketSlice';

import {
  Grid,
  CardContent,
  Card,
  makeStyles,
  Button,
  Divider,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { styles } from './styles';
import { useEffect } from 'react';
import { navigate } from '@reach/router';

const useStyles = makeStyles(styles);

const PaymentSuccess = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetTicketsState());
  }, [dispatch]);

  return (
    <>
      <Head title="Compra exitosa" />
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={10} sm={8} md={5} lg={4}>
          <Card>
            <CardContent style={{ padding: '2rem 0 4rem' }}>
              <div>
                <h2 className={classes.title}>
                  Compra realizada exitosamente{' '}
                  <CheckCircle
                    fontSize="small"
                    style={{ color: '#4CAF50', marginTop: 2 }}
                  />
                </h2>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.buttonWrapper}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate('/perfil/mis-cartones')}
                >
                  Ver mis cartones
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentSuccess;
