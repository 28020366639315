import axios from '../config/axios';
import qs from 'qs';

const TICKETS = '/tickets';

//Function for generated randm Number for paginated
const generateLimitedPaginated = (numbers) => {
  if (numbers < 3) {
    return 11;
  } else if (numbers < 5) {
    return 7;
  } else if (numbers < 7) {
    return 3;
  } else if (numbers < 8) {
    return 2;
  } else {
    return 1;
  }
};

export const fetchTickets = async (
  _id,
  size = 0,
  tickets = [],
  numbers = [],
  numbersLength,
  randomPaginated = false,
  cancelToken
) => {
  const limitedPaginated = generateLimitedPaginated(numbersLength);

  try {
    let paginated = 1;

    //if randomPaginated is True, paginated will be a number random between 1 and 11
    if (randomPaginated) {
      paginated = Math.floor(Math.random() * (limitedPaginated - 1 * 1) + 1);

      const { data } = await axios.get(`${TICKETS}/pagination`, {
        params: {
          draw: _id,
          size,
          tickets: tickets,
          paginated,
          filter: numbers,
        },
        cancelToken,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });

      return data;
    }

    while (paginated <= limitedPaginated) {
      const { data } = await axios.get(`${TICKETS}/pagination`, {
        params: {
          draw: _id,
          size,
          tickets: tickets,
          paginated,
          filter: numbers,
        },
        cancelToken,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });

      if (data.data?.length !== 0) {
        return data;
      }

      if (paginated === limitedPaginated) {
        return data;
      }
      paginated++;
    }
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const purchaseTickets = async (ticketIds) => {
  try {
    const { data } = await axios.patch(`${TICKETS}/in-stock`, ticketIds, {
      params: { paymentMethod: 'recharge' },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const fetchPurchaseTickets = async ({ draw }) => {
  try {
    const { data } = await axios.get(`${TICKETS}`, {
      params: { draw },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const scratchedTickets = async (_id, scratched) => {
  try {
    const { data } = await axios.patch(`${TICKETS}/${_id}/scratched`, {
      scratched,
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const verifyTicketInStock = async ({ _id }) => {
  try {
    const { data } = await axios.get(`${TICKETS}/availability`, {
      params: {
        id: _id,
      },
    });
    return { isStock: data.data, error: false };
  } catch ({ response }) {
    if (response) {
      return { message: response.message, error: true };
    }
  }
};
