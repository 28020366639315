import React from 'react';
import Layout from '../components/layouts';
import { Redirect, useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import {
  accessTokenSelector,
  userRoleSelector,
} from '../store/reducers/slices/loginSlice';

const RouteWrapper = ({ path, isPrivate, isBranch, component: Component }) => {
  const token = useSelector(accessTokenSelector);
  const role = useSelector(userRoleSelector);
  const location = useLocation();

  if (isPrivate) {
    if (isBranch && role === 'user') {
      return <Redirect to="/" noThrow />;
    }

    if (!token) {
      localStorage.setItem('redirectPath', location.pathname);
      return <Redirect to="/inicio-de-sesion" noThrow />;
    }
  }

  if (token && (path === '/inicio-de-sesion' || path === '/registro')) {
    return <Redirect to="/" noThrow />;
  }

  return (
    <Layout isPrivate={isPrivate} path={path}>
      <Component />
    </Layout>
  );
};

export default RouteWrapper;
