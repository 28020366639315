import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateUser } from '../../../api/auth';

const initialState = {
  loading: false,
  statusCode: null,
  message: null,
  user: null,
};

export const updateUserThunk = createAsyncThunk(
  'auth/update-pass',
  async ({ _id, userData }, { rejectWithValue }) => {
    try {
      const { data } = await updateUser(_id, userData);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    delayResponse: (state) => {
      state.loading = true;
    },

    resetUserState: (state) => initialState,
  },
  extraReducers: {
    [updateUserThunk.fulfilled]: (state, action) => {
      const { message, statusCode, data } = action.payload;

      state.user = data;
      state.statusCode = statusCode;
      state.message = message;
      state.loading = false;
    },
    [updateUserThunk.rejected]: (state, action) => {
      const { message, statusCode } = action.payload;

      state.message = message;
      state.statusCode = statusCode;
      state.loading = false;
    },
  },
});

export const { resetUserState, delayResponse } = userSlice.actions;

export const updateUserAction = (data) => (dispatch) => {
  setTimeout(() => {
    dispatch(updateUserThunk(data));
  }, 1000);

  dispatch(delayResponse());
};

export const updateUserSelector = ({ user }) => ({
  user: user.user,
  loading: user.loading,
  message: user.message,
  statusCode: user.statusCode,
});

export default userSlice.reducer;
