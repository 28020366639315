import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import { Link } from 'react-scroll';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Countdown from 'react-countdown';

import KinoLogo from '../../assets/images/logo1-preview.png';

import isEmpty from 'lodash.isempty';

import { headerStyle } from './styles';

import {
  userRoleSelector,
  userSelector,
} from '../../store/reducers/slices/loginSlice';
import moment from 'moment';

const useStyles = makeStyles(headerStyle);

export const Header = ({ isPrivate, drawActive }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { accessToken } = useSelector(({ login }) => login);
  const user = useSelector(userSelector);
  const role = useSelector(userRoleSelector);
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ pathname: location?.pathname });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    window.location.replace('/');

    localStorage.clear();
  };

  // Renderer callback with condition
  const responsiveRenderer = ({ days, hours, minutes, seconds }) => (
    <span style={{ color: days <= 1 ? '#ad0b0b' : 'white', fontSize: 20 }}>
      {`${days}D ${hours}h ${minutes}m ${seconds}s`}
    </span>
  );

  const renderer = ({ days, hours, minutes, seconds }) => (
    <span style={{ color: days <= 1 ? '#ad0b0b' : 'white', fontSize: 16 }}>
      {` ${days}D ${hours}h ${minutes}m ${seconds}s`}
    </span>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.mainHeader}>
        <div className={classes.mainHeaderWrapper}>
          <div className={classes.logo}>
            
            <img src={KinoLogo} alt="logo" onClick={() => navigate('/')} />

            {/*<Hidden mdDown>
              {!isEmpty(drawActive) && user?.balance > 0 && (
                <div className={classes.countdown}>
                  <div>
                    <span>Tus recargas vencen en </span>
                  </div>
                  <div style={{ marginLeft: 4 }}>
                    <Countdown
                      date={moment(drawActive.endDate)
                        .add(12, 'hours')
                        .add(5, 'hour')
                        .toISOString()}
                      renderer={renderer}
                    />
                  </div>
                </div>
              )}
                      </Hidden>*/}
          </div>
          {responsive ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div className={classes.mainHeaderRight}>
              <nav className={classes.mainNavWrapper}>
                <ul className={classes.mainHeaderList}>
                  {accessToken ? (
                    <>
                      {role === 'branch' ? (
                        <li>
                          <Link to="/recharge">Recargas</Link>
                        </li>
                      ) : (
                        <>
                          <li>
                            <Link to="/perfil/mis-cartones">Mis cartones</Link>
                          </li>
                          {/* <li>
                            <Link to="/perfil/ingresar-recarga">
                              Ingresa una Recarga
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/perfil/mis-cartones">Mi Perfil</Link>
                          </li>
                          <li>
                            <Link to="/compra-cartones">Compra un cartón</Link>
                          </li>
                          <li>
                           {/*  <Link to="/encuentranos">Encuéntranos</Link> */}
                          </li>
                        </>
                      )}
                      <li style={{ cursor: 'pointer' }}>
                        <div onClick={() => logout()}>Cerrar Sesión</div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to="/inicio-de-sesion">Iniciar Sesión</Link>
                      </li>
                      <li>
                        <Link to="/registro">Registro</Link>
                      </li>
                      <li>
                       {/*  <Link to="/encuentranos">Encuéntranos</Link> */}
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </AppBar>
      {responsive && (
        <Drawer
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes?.drawerPaper,
          }}
        >
          <div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon className={classes.drawerIcon} />
            </IconButton>
          </div>
          <nav>
            <ul className={classes.drawerList}>
              {accessToken ? (
                <>
                  <li>
                    <Link to="/perfil/mis-cartones">Mi Perfil</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  <>
                    <li>
                      <Link to="/perfil/mis-cartones">
                        <div id="balance">Mis cartones</div>
                      </Link>
                    </li>
                    <Divider className={classes.drawerDivider} />
                  </>
                  <li>
                    {/*{!isEmpty(drawActive) && user?.balance > 0 && (
                      <div>
                        <span> Tus recargas vencen en </span>
                        {
                          <Countdown
                            date={moment(drawActive?.endDate)
                              .add(12, 'hours')
                              .add(1, 'hour')
                              .toISOString()}
                            renderer={responsiveRenderer}
                          />
                        }
                      </div>
                      )}*/}
                    <Divider className={classes.drawerDivider} />
                  </li>
                  <li>
                    <Link to="/compra-cartones">Compra un cartón</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  <li>
                    <Link to="/">Instrucciones</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  <li>
                    <Link to="/acerca-de-nosotros">Acerca de nosotros</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  {/* <li>
                    <Link to="/contacto">Contacto</Link>
                  </li> */}
                  <Divider className={classes.drawerDivider} />
                  <li>
                    {/* <Link to="/encuentranos">Encuéntranos</Link> */}
                  </li>
                  <Divider className={classes.drawerDivider} />
                  <li>
                    <div id="logout" onClick={() => logout()}>
                      Cerrar Sesión
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/inicio-de-sesion">Iniciar Sesión</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  <li>
                    <Link to="/registro">Registro</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  <li>
                    <Link to="/">Instrucciones</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  <li>
                    <Link to="/acerca-de-nosotros">Acerca de nosotros</Link>
                  </li>
                  <Divider className={classes.drawerDivider} />
                  {/* <li>
                    <Link to="/contacto">Contacto</Link>
                  </li> */}
                  <Divider className={classes.drawerDivider} />
                  <li>
                    {/* <Link to="/encuentranos">Encuéntranos</Link> */}
                  </li>
                </>
              )}
            </ul>
          </nav>
        </Drawer>
      )}
    </div>
  );
};
