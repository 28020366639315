import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { scratchedTickets } from '../../../api/ticket';

const initialState = {
  listSelected: [],
  updateListSelected: [],
  isScratchetd: false,
};

export const scratchedTicketsThunk = createAsyncThunk(
  'tickets-scrachet',
  async ({ _id, scratched: numbersScratched }, { rejectWithValue }) => {
    try {
      const { data } = await scratchedTickets(_id, numbersScratched);

      const scratched = data.scratched;

      return { scratched };
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      rejectWithValue(error.response.message);
    }
  }
);

export const ticketSlice = createSlice({
  name: 'ticketList',
  initialState,
  reducers: {
    saveListSelectedReducer: (state, action) => {
      state.listSelected = action.payload;
      state.updateListSelected = action.payload;
      state.loading = false;
    },
    updateListSelectedReducer: (state, action) => {
      const { tickets } = action.payload;

      state.updateListSelected = tickets;
    },
    resetListSelectedReducer: (state, action) => {
      const { tickets } = action.payload;

      state.listSelected = tickets;
    },
    delayResponse: (state) => {
      state.loading = true;
    },
    resetTicketsState: (state) => initialState,
  },
  extraReducers: {
    [scratchedTicketsThunk.fulfilled]: (state, action) => {
      const { scratched } = action.payload;

      state.isScratchetd = scratched;

      state.generateLoading = false;
    },
  },
});

export const {
  saveListSelectedReducer,
  delayResponse,
  resetTicketsState,
  resetListSelectedReducer,
  updateListSelectedReducer,
} = ticketSlice.actions;

export const buyticketAction = (item, navigate) => (dispatch) => {
  setTimeout(() => {
    dispatch(saveListSelectedReducer(item));
    navigate('/confirmar-compra');
  }, 1000);

  dispatch(delayResponse());
};

export const ticketSelector = (state) => ({
  listSelected: state.ticketList.listSelected,
  updateListSelected: state.ticketList.updateListSelected,
  loading: state.ticketList.loading,
});

export const listSelectedSelector = ({ ticketList }) => ticketList.listSelected;

export default ticketSlice.reducer;
