import React, { lazy, useEffect, useState } from 'react';
import { Router, Redirect } from '@reach/router';
import RouterWrapper from './RouteWrapper';

import LazyLoader from '../components/LazyLoader';

import PaymentSuccess from '../pages/PaymentSuccess';
import { getDrawsTv } from '../api/tv-draws';

const Login = lazy(() => import('../containers/Login'));
const Register = lazy(() => import('../containers/Register'));
// const Contact = lazy(() => import('../containers/Contact'));
const AboutUs = lazy(() => import('../containers/AboutUs'));
const FindUs = lazy(() => import('../containers/FindUs'));

const NewsLetterEmail = lazy(() => import('../containers/NewsLetterEmail'));
const Home = lazy(() => import('../pages/home'));
const Blog = lazy(() => import('../containers/Blog'));
const Lottery = lazy(() => import('../containers/Lottery'));
const Profile = lazy(() => import('../containers/Profile'));
/* const Press = lazy(() => import('../pages/Press/Press')) La nota de prensa debe ser activada solo cuando exista un sorteo especial */

const PaymentDetail = lazy(() => import('../pages/PaymentDetail'));
const TDCPayment = lazy(() => import('../containers/TDCPayment'));
const PhysicalRecharge = lazy(() => import('../containers/physicalRecharge'));
const C2PPayment = lazy(() => import('../containers/C2PPayment'));

const PaymentMethod = lazy(() => import('../containers/PaymentMethod'));
const Winners = lazy(() => import('../containers/Winners'));
const SendEmail = lazy(() => import('../containers/SendEmail'));
const ResetPassword = lazy(() => import('../containers/ResetPassword'));
const PaypalPayment = lazy(() => import('../containers/PayPalPayment'));
const Sales = lazy(() => import('../pages/Sales'));
const TvDraws = lazy(() => import('../containers/TvDraws'));
const GamesBaseball = lazy(() => import('../containers/GamesBaseball'));

const Routes = () => {
  const [links, setLinks] = useState([]);

  const fetchData = async () => {
    try {
      const tvDraws = await getDrawsTv();

      const routes = tvDraws.map((item) => ({
        name: item.name,
        path: `/tv-draw/${item.name}`,
        component: TvDraws,
      }));

      setLinks(routes);
    } catch (error) {
      console.error('Error fetching draws:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Suspense fallback={<></>}>
      <Router primary={false}>
        <ScrollToTop path="/">
          <RouterWrapper path="/" component={Home} />
          {/* <RouterWrapper path="/inicio-de-sesion" component={Login} /> */}
          <RouterWrapper path="/registro" component={Register} />
          {/* <RouterWrapper path="/contacto" component={Contact} /> */}
          <RouterWrapper path="/encuentranos" component={FindUs} />
          <RouterWrapper path="/acerca-de-nosotros" component={AboutUs} />
          <RouterWrapper path="/ingresar-correo" component={NewsLetterEmail} />
          <RouterWrapper path="blog/:noticeId" component={Blog} />
          {/* <RouterWrapper path="/press" component={Press} /> */}
          <RouterWrapper path="perfil/:route" component={Profile} isPrivate />

          <RouterWrapper path="/tv-draw" component={TvDraws} />

          <RouterWrapper path="/games-baseball" component={GamesBaseball} />

          <RouterWrapper
            path="/compra-cartones"
            component={Lottery}
            isPrivate
          />
          {/* <RouterWrapper
            path="/CODIGO"
            component={PhysicalRecharge}
            isPrivate
          /> */}

          <RouterWrapper
            path="/metodo-de-pago"
            component={PaymentMethod}
            isPrivate
          />
          {<RouterWrapper path="/pago-tdc" component={TDCPayment} isPrivate />}
          {/* <RouterWrapper
            path="/canjear-codigo"
            component={PhysicalRecharge}
            isPrivate
          /> */}
          {/*<RouterWrapper
              path="/pago-paypal"
              component={PaypalPayment}
              isPrivate
            /> */}

          <RouterWrapper path="/pago-c2p" component={C2PPayment} isPrivate />

          <RouterWrapper path="/resetear-contrasena" component={SendEmail} />
          <RouterWrapper
            path="/nueva-contrasena/:token"
            component={ResetPassword}
          />
          <RouterWrapper path="perfil/:route" component={Profile} isPrivate />
          <RouterWrapper
            path="/compra-cartones"
            component={Lottery}
            isPrivate
          />
          <RouterWrapper path="/ganadores-de-sorteos" component={Winners} />
          <RouterWrapper
            path="/elegir-metodo-de-pago"
            component={PaymentMethod}
            isPrivate
          />
          {<RouterWrapper path="/pago-tdc" component={TDCPayment} isPrivate />}
          <RouterWrapper
            path="/confirmar-compra"
            component={PaymentDetail}
            isPrivate
          />
          <RouterWrapper
            path="/compra-exitosa"
            component={PaymentSuccess}
            isPrivate
          />

          <RouterWrapper path="/ventas" component={Sales} isPrivate />

          <Redirect from="/" to="/" default noThrow />
        </ScrollToTop>
      </Router>
    </React.Suspense>
  );
};

const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};

export default Routes;
