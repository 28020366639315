import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from '@reach/router';

import { fetchUserBalance } from '../../api/auth';
import { updateUserBalance } from '../../store/reducers/slices/loginSlice';
import { resetTicketsState } from '../../store/reducers/slices/ticketSlice';
import TopBanner  from '../../pages/Home/TopBanner';
import { homeStyle } from '../../pages/Home/styles';
import { makeStyles } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import { Header } from '../Header';
import { Footer } from '../Footer';

import 'moment/locale/es'
import apiDraw from '../../api/draw';

const useStyles = makeStyles(homeStyle);


const Layout = ({ children, isPrivate }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector(({ login }) => login);
  const [drawActive, setDrawActive] = useState(null);
  const classes = useStyles();

  
  useEffect(() => {
    const fetchDrawActiveData = async () => {
      const { data } = await apiDraw.fetchDrawActive();

      setDrawActive(data);
    };

    fetchDrawActiveData()
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      if (user?._id) {
        const { data } = await fetchUserBalance(user?._id);

        if (data?.statusCode >= 200 && data?.statusCode < 300) {
          dispatch(
            updateUserBalance({
              balance: data.data.balance,
              rechargeType: 'add',
            })
          );
        }
      }
    };

    fetchData();
  }, [pathname, user, dispatch]);

  const handleLoad = useCallback(() => {
    dispatch(resetTicketsState());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('unload', handleLoad);

    return () => window.removeEventListener('unload', handleLoad);
  }, [handleLoad]);

  return (
    <div>
      {pathname !== '/' && <TopBanner isPrivate={isPrivate} drawActive={drawActive} classes={classes} />}
     { <div>{children}</div>}
    </div>
  );
};

export default Layout;
