import axios from 'axios';

export const generateCancelToken = () => {
  const CancelToken = axios.CancelToken;
  const sourceCancelToken = CancelToken.source();

  return sourceCancelToken
}

const instance = axios.create({
  baseURL:process.env.REACT_APP_API_PORT
});

export default instance;
