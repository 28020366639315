import axios from '../config/axios';
import { SweetError } from '../utils/SweetAlert';

const AUTH = '/auth';
const AUTH_API = '/auth-api';

export const register = async (user) => {
  delete user.passwordConfirm;

  try {
    const { data } = await axios.post(`${AUTH}/register`, user);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};


export const loginApi = async (payload) => {
  try {

    const { data } = await axios.post(`${AUTH_API}/login`, payload);
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};


export const serialsApi = async (payload, signature) => {
  try {

    const { data } = await axios.post(`${AUTH_API}/serials`, payload, {
      headers: {
        'http-x-auth-signature': signature 
      },
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};


export const generateSerialsApi = async (payload, signature) => {
  try {

    const { data } = await axios.post(`${AUTH_API}/generate-serials`, payload, {
      headers: {
        'http-x-auth-signature': signature 
      },
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};

export const postPayment = async (payload) => {
  try {
    const response = await axios.post(`${AUTH_API}/payment`, payload);
    
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`Error: ${response.status}`);
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la solicitud'
      );
    }
    throw new Error(error.message || 'Error desconocido');
  }
};


export const generateSerialClient = async (payload) => {
  try {

    const { data } = await axios.post(`${AUTH_API}/generate-serials-client`, payload, {
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};


export const gamesApi = async (signature) => {
  try {
    const { data } = await axios.get(`${AUTH_API}/games`, {
      headers: {
        'http-x-auth-signature': signature
      },
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};


export const getPurchases = async (token, date,page) => {
  try {
    let url = `${AUTH_API}/purchased-serials`;

    console.log('date',date)
    if (date) {
      url += `?date=${date}&page=${page}`
    } else {
      url += `?page=${page}`
    }

    const res = await axios.get(url, {
      headers: {
        'http-x-utk': token
      },
    });

    const {data, totalItems} = res.data;
    
    return {checkouts: data, totalItems};
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};

export const login = async (user) => {
  const { username, password } = user;

  try {
    const { data } = await axios.post(
      `${AUTH}/login`,
      {},
      { auth: { username, password } }
    );

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};
//Login Facebook
export const loginFacebook = async (userFacebook) => {
  try {
    const { data } = await axios.post(`auth/facebookLogin`, userFacebook);
    if (data) {
      return data;
    }
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};
//Login Google
export const loginGoogle = async (userGoogle) => {
  try {
    const dataGoogle = await axios.post('/auth/googleLogin', {
      tokenId: userGoogle.tokenId,
    });
    if (dataGoogle) {
      const { data } = dataGoogle;
      return data;
    }
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const fetchUserBalance = async (_id) => {
  try {
    const data = await axios.get(`/users/${_id}/balance`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const sendResetPassEmail = async (email) => {
  try {
    const { data } = await axios.post(`${AUTH}/reset-password`, {
      email,
      role: 'user',
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const updatePassword = async (token, password) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const { data } = await axios.post(
      `users/password`,
      { token, password },
      config
    );

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const updateUser = async (_id, userData) => {
  try {
    const { data } = await axios.put(`users/${_id}`, userData);
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const addEmailNotification = async (email) => {
  try {
    const { data } = await axios.patch(`${AUTH}/notifications`, null, {
      params: { email },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};
