import { navigate } from '@reach/router';
import Swal from 'sweetalert2';

export const SweetSuccessNavigate = (
  navigate,
  route = '',
  title = '',
  balance
) => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 1500,
  }).then(() => {
    if (balance > 0) {
      Swal.fire({
        icon: 'info',
        title: `Te has registrado exitosamente.`,
      });
    }

    navigate(route, { replace: true });
  });
};

export const SweetSuccess = (title = '') => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: true,
    allowOutsideClick: false,
    confirmButtonText: 'Aceptar',
  });
};

export const SweetSuccessConfirmation = (
  title = '',
  route = '',
  navigate,
  Button = 'Aceptar'
) => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: true,
    confirmButtonText: Button,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      navigate(route, { replace: true });
    }
  });
};

export const SweetErrorConfirmation = (
  title = '',
  route = '',
  navigate,
  titleButton = 'Ver mis cartones.'
) => {
  Swal.fire({
    icon: 'error',
    title: title,
    showConfirmButton: true,
    confirmButtonText: titleButton,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      navigate(route, { replace: true });
    }
  });
};
export const SweetErrorRecharge = (
  title = '',
  route = '',
  navigate,
  titleButton = 'Ver mis cartones.'
) => {
  Swal.fire({
    icon: 'error',
    title: title,
    showConfirmButton: true,
    confirmButtonText: 'Recargar',
    allowOutsideClick: false,
    showCancelButton: true,
    cancelButtonColor: 'cancelar',
  }).then((result) => {
    if (result.isConfirmed) {
      navigate('/CODIGO', { replace: true });
    } else {
      navigate(route, { replace: true });
    }
  });
};

export const SweetSuccessWithDispatch = (
  title = '',
  buttonTitle,
  route = '',
  navigate,
  dispatch,
  func
) => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: true,
    confirmButtonText: buttonTitle,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      navigate(route, { replace: true });

      setTimeout(() => {
        if (func.hasOwnProperty('resetTicketsState')) {
          dispatch(func.resetTicketsState());
        }
        if (func.hasOwnProperty('updateUserBalance')) {
          dispatch(
            func.updateUserBalance({
              payload: { rechargeType: 'add', balance: 1 },
            })
          );
        }
      }, 500);
    }
  });
};

export const SweetInfo = (title = '') => {
  Swal.fire({
    icon: 'info',
    title: title,
    showConfirmButton: true,
    confirmButtonText: 'Aceptar',
    allowOutsideClick: false,
    timer: 3000,
  });
};

export const SweetError = (title = '') => {
  Swal.fire({
    icon: 'error',
    title: title,
    showConfirmButton: false,
    timer: 2500,
  });
};

export const SweetErrorOk = (title = '') => {
  Swal.fire({
    icon: 'error',
    title: title,
    showConfirmButton: true,
    confirmButtonText: 'Ok',
  });
};

export const SweetWarning = (title = '') => {
  Swal.fire({
    icon: 'warning',
    title: title,
    showConfirmButton: false,
    timer: 2500,
  });
};

export const SweetWarningConfirmation = (title = '') => {
  Swal.fire({
    icon: 'warning',
    title: title,
    showConfirmButton: true,
    confirmButtonText: 'Aceptar',
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
    }
  });
};

export const SweetLoading = (open, cancel) => {
  if (open) {
    Swal.fire({
      title: '¡Por favor espere!',
      html: 'Estamos buscando más cartones',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      if (result.isDismissed) {
        cancel.cancel();
      }
    });
  } else {
    Swal.close();
  }
};

export const SweetWarningNavigate = (title = '', html = '', route = '') => {
  Swal.fire({
    icon: 'warning',
    title: title,
    html: html,
    showConfirmButton: true,
    confirmButtonText: 'Ok',
    timer: 5000,
  }).then((result) => {
    navigate(route, { replace: true });
  });
};

export const SweetInputOtp = (
  title = '',
  data = {},
  tickets = [],
  paymentFunction
) => {
  Swal.fire({
    title: title,
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off',
    },
    confirmButtonText: 'Pagar',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#d33',
    showLoaderOnConfirm: true,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      paymentFunction({ ...data, otp: result.value }, tickets);
    }
  });
};
