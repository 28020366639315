/* import BannerChallenge from '../../assets/images/BannerChallenge.webp'; */
import Bannerprincipal1 from '../../assets/images/Bannerprincipal1.jpg';
import Bannerprincipal2 from '../../assets/images/Bannerprincipal2.jpg';
import Bannerprincipal3 from '../../assets/images/Bannerprincipal3.jpg';
/* import Banner4 from '../../assets/images/Banner4.jpg'; */ // El banner4 queda reservado para sorteos especiales
import bannerRecharge from '../../assets/images/bannerRecharge.jpg';
import BackgroundOrange from '../../assets/images/Capa-3.webp';
import newEncuentranos from '../../assets/images/nuevoEncuentranos.webp';
import BackgroundGeometry from '../../assets/images/BackgroundGeometry.webp';
import BackgroundGeometry2 from '../../assets/images/BackgroundGeometry2.webp';
import BackgroundShow from '../../assets/images/BackgroundShow.webp';
import BackgroundConfetty from '../../assets/images/BackgroundConfetty.webp';
import KnowyourTicket from '../../assets/images/KnowyourTicket.webp';

export const homeStyle = ({ breakpoints, props }) => ({
  root: {
    display: 'block',
  },

  headNavbar: {
    height: '12vh',
    position: 'relative',
    paddingBottom: 5,
    paddingTop: 5,

    [breakpoints.down('sm')]: {
      height: '15vh',
      position: 'relative',
      paddingBottom: '55px',
      paddingTop: '35px',
    },

    [breakpoints.down('xl')]: {
      padding: '4vh',
    },

    [breakpoints.down('md')]: {
      height: '17vh',
    },
  },

  topBannerRoot: {
    width: '100%',
    zIndex: 1000,
  },

  imageLogo: {
    width: '56%',
    marginTop: '7px',
  },

  imageLogoTrue: {
    width: '56%',
    marginTop: '53px',

    [breakpoints.down('xl')]: {
      padding: '1vh',
    },

    [breakpoints.down('md')]: {
      height: '15vh',
      width: '134px',
      height: '125px',
    },

    [breakpoints.down('sm')]: {
      height: '26vh',
      width: '111%',
      paddingTop: '1px',
      paddingBottom: '63px',
      marginLeft: '270px',
      marginRight: '-176px',
    },

    [breakpoints.down('xs')]: {
      height: '20vh',
      width: '119%',
      paddingTop: '3px',
      paddingBottom: '63px',
      marginLeft: '270px',
      marginRight: '-176px',
    },
  },

  topBannerContainerTrue: {
    textAlign: '-webkit-center',

    [breakpoints.down('xl')]: {
      paddingBottom: 10,
    },

    [breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },

  topBannerContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',

    [breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },

  topBannerWrapperTrue: {
    width: '59%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  topBannerWrapper: {
    width: '40%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',

    [breakpoints.down('md')]: {
      width: '100%',
    },

    [breakpoints.down('xs')]: {
      '& button': {
        margin: 0,
        padding: 5,
      },
    },

    '& p, & svg': {
      color: '#fff',
    },
  },

  buttonEncuentranos: {
    padding: '0px 10px',

    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  bannerSlider: {
    height: '90vh',
    width: '100%',
    position: 'relative',

    [breakpoints.down('xl')]: {
      height: '100vh',
    },

    [breakpoints.down('lg')]: {
      height: '90vh',
    },

    [breakpoints.down('sm')]: {
      height: '80vh',
    },

    [breakpoints.down('xs')]: {
      height: '60vh',
    },
  },

  /* // test banner Recharge
  bannerRecharge: {
    backgroundImage: `url('${bannerRecharge}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',

    [breakpoints.down('md')]: {
      backgroundSize: 'cover',
    },

    [breakpoints.down('sm')]: {
      backgroundPosition: '10% center',
      backgroundSize: '100% 100%'
    },

    [breakpoints.down('xs')]: {
      backlgroundPOsition: '0% center',
      backgroundSize: '180% 100%'
    }
  }, */

  // El Bannerprincipal1 se debe cambiar al Banner4 cuando exista algun sorteo especial
  bannerSliderContent1: {
    backgroundImage: `url('${Bannerprincipal1}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    /* backgroundPosition: 'center center', */

    [breakpoints.down('md')]: {
      backgroundSize: 'cover',
      // backgroundPosition: 'center left 22px',
    },

    [breakpoints.down('sm')]: {
      /* backgroundPosition: '10% center', */
      backgroundSize: '100% 100%',
    },
    [breakpoints.down('xs')]: {
      /* backgroundPosition: '0% center', */
      backgroundSize: '180% 100%',
    },
  },

  bannerSliderContent2: {
    backgroundImage: `url('${Bannerprincipal2}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    /* backgroundPosition: 'center center', */

    [breakpoints.down('md')]: {
      backgroundSize: 'cover',
      backgroundPosition: '30% center',
    },

    [breakpoints.down('sm')]: {
      backgroundPosition: '5% center',
    },

    [breakpoints.down('xs')]: {
      backgroundSize: 'cover',
      backgroundPosition: '15% center',
    },
  },

  bannerSliderContent3: {
    backgroundImage: `url('${Bannerprincipal3}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    /* backgroundPosition: 'center center', */
    marginRight: '2550px',

    [breakpoints.down('md')]: {
      backgroundSize: 'cover',
      /* backgroundPosition: 'center', */
    },

    [breakpoints.down('sm')]: {
      /* backgroundPosition: 'center', */
      width: '100px',
    },

    [breakpoints.down('xs')]: {
      backgroundSize: 'cover',
    },
  },

  bannerBackground: {
    width: '97%',
    zIndex: '10',
    position: 'absolute',
    top: 70,
    left: 35,
    [breakpoints.down('md')]: {
      top: 110,

      left: 23,
    },
    [breakpoints.down('xs')]: {
      top: 100,
      left: 15,
    },

    [breakpoints.down('457')]: {
      top: 150,
      left: 10,
    },

    [breakpoints.down('400')]: {
      width: '100%',
      justifyContent: 'space-around',
      left: 0,
    },
  },
  logoAndinitoContainerTrue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',
    position: 'absolute',
    top: '-87px',
    right: '506px',

    [breakpoints.down('xl')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '25%',
      position: 'absolute',
      top: '-87px',
      right: '679px',
    },

    [breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '25%',
      position: 'absolute',
      top: '-87px',
      right: '506px',
    },

    [breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '25%',
      position: 'absolute',
      top: '-83px',
      right: '354px',
    },

    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '21%',
      position: 'absolute',
      top: '-83px',
      right: '530px',
    },

    [breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '19%',
      position: 'absolute',
      top: '-83px',
      right: '524px',
    },
  },

  logoAndinitoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25%',

    [breakpoints.down('md')]: {
      /* '& img': {
        display: 'none',
      }, */
    },

    [breakpoints.down('sm')]: {
      display: 'none',
      /* 
      '& img': {
        width: '20%',
        maxWidth: 20,
      }, */
    },
    [breakpoints.down('420')]: {
      display: 'none',
      /* '& img': {
        width: '20px',
      }, */
    },
  },

  mainHeaderList: {
    display: 'flex',
    alignItems: 'center',
    margin: '65px 0 -17px 0px',
    padding: 0,
    listStyle: 'none',
    justifyContent: 'flex-end',

    '& li': {
      fontSize: '.84375rem',
      position: 'relative',

      '&:after': {
        bottom: 0,
        content: '""',
        display: 'block',
        height: '2px',
        left: '50%',
        position: 'absolute',
        background: '#fff',
        transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
        width: 0,
      },

      '&:hover:after': {
        width: '50%',
        left: '23%',
      },

      '& a': {
        color: '#fff',
        textDecoration: 'none',
        padding: '8px 4px',
        position: 'relative',

        [breakpoints.up('md')]: {
          padding: '8px 4px',
          margin: '0 0px',
        },
        [breakpoints.up('lg')]: {
          padding: '8px 8px',
          margin: '0 0px',
          fontSize: '.95rem',
        },
      },
    },

    '& li:last-child': {
      '&::after': {
        display: 'none',
      },
    },

    '& button': {
      padding: '5px',
    },
  },

  buttonLogout: {
    color: '#fff',
    fontSize: '1.5rem',
    transition: '.3s all ease',
    '&:hover': {
      color: '#ff5001',
    },
  },

  /**Last Results */

  ButtonInformation: {
    position: 'absolute',
    top: '74%',
    right: '60%',
    zIndex: '2',
    color: '#fff',
    marginTop: '3rem',
    width: '250px',
    height: '54px',

    [breakpoints.down('md')]: {
      right: '72%',
      top: '80%',
      marginRight: '-4rem',
      marginTop: '3rem',
    },

    [breakpoints.down('sm')]: {
      right: '72%',
      top: '80%',
      marginRight: '-4rem',
      marginTop: '3rem',
    },
  },

  buttonInfo: {
    color: '#fff',
    fontSize: '20px',
    borderRadius: 40,
    borderWidth: '2px',
  },

  lastResultsRoot: {
    position: 'absolute',
    top: '40%',
    right: '8%',
    zIndex: '2',
    color: '#fff',

    [breakpoints.down('md')]: {
      right: '10%',
    },

    [breakpoints.down('sm')]: {
      position: 'relative',
      top: '0%',
      right: '0%',
      padding: '5px 5px',
    },
  },

  lastResultResponsive: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#002565',
  },

  lastResults: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& h1:nth-child(2)': {
      fontWeight: 800,

      [breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
  },

  mobileButton: {
    fontSize: '1.5rem',
    marginRight: '5px',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  lastReusltsNumbers: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',

    [breakpoints.down('sm')]: {
      padding: '0px 20%',
    },
    [breakpoints.down('xs')]: {
      padding: '0',
    },

    '& p': {
      border: '1px solid #ff5001',
      borderRadius: 20,
      padding: '5px 10px',
      fontSize: '1.2rem',
      marginRight: 5,

      [breakpoints.down('xs')]: {
        fontSize: '1rem',
        padding: '5px 8px',
      },
    },
  },

  lastResultsPot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& h1': {
      fontWeight: 800,

      [breakpoints.down('sm')]: {
        fontSize: '5rem',
      },
    },
  },

  lastResultsPlayToday: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  /**Video Content Section */
  videoContentRoot: {
    padding: 10,
  },

  video: {
    width: '90%',
    height: '280px',
    boxShadow: '-30px 30px 0px #ff5001',

    [breakpoints.down('sm')]: {
      width: '100%',
      boxShadow: '-20px 20px 0px #ff5001',
    },
  },

  videoContentParagrah: {
    '& h1': {
      fontWeight: 'bolder',

      [breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },

    '& button': {
      color: '#ff5001',
      border: '2px solid #ff5001',
      borderRadius: 10,
      padding: '5px 40px',
    },

    [breakpoints.down('sm')]: {
      marginTop: 50,
    },
  },

  videoContentImageContainer: {
    '& img': {
      width: '150%',

      [breakpoints.down('sm')]: {
        marginLeft: 10,
        width: '120%',
      },

      [breakpoints.down('xs')]: {
        marginLeft: -30,
        width: '120%',
      },
    },
  },

  videoContentTypeWinnners: {
    marginTop: 50,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      color: '#485afc',
      fontSize: '2rem',
    },

    '& h1': {
      color: '#ff5001',
      fontWeight: 'bold',
    },

    '& h2': {
      fontSize: '1rem',
    },
  },

  /*Sell Andinitio Section */

  sellAndinitoRoot: {
    backgroundImage: `url('${newEncuentranos}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginBottom: 100,
    height: '88vh',
    position: 'relative',

    [breakpoints.between(1300, 1400)]: {
      height: '90vh',
    },

    [breakpoints.between(1000, 1300)]: {
      height: '75vh',
    },

    [breakpoints.down('sm')]: {
      padding: '100px 0px 30px 20px',
      height: 'auto',
      marginBottom: 70,
    },
  },

  sellAndinitoContainer: {
    color: '#fff',
    position: 'relative',
    textAlign: 'center',

    '&::before': {
      bottom: '100%',
      content: '""',
      display: 'block',
      height: 60,
      width: 2,
      left: '50%',
      position: 'absolute',
      background: '#fff',
    },
  },

  sellAndinitoCardContainer: {
    position: 'absolute',
    marginTop: 35,

    [breakpoints.down('md')]: {
      paddingLeft: 20,
    },

    [breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: -15,
      padding: '0px 30%',
    },

    [breakpoints.down('xs')]: {
      padding: '0px 5%',
    },
  },

  phoneRoulesContainer: {
    width: 400,
    height: 200,
    position: 'absolute',
    zIndex: '10',
    top: 100,
    right: 50,

    [breakpoints.down('md')]: {
      display: 'none',
    },

    '& img': {
      width: '100%',
    },
  },

  sellAndinitoButtonInvestContainer: {
    marginTop: 30,

    [breakpoints.down('sm')]: {
      width: 500,
      position: 'absolute',
      bottom: -80,
      marginLeft: -20,
    },
  },

  buttonInvestParent: {
    margin: '0px 30px',

    [breakpoints.down('sm')]: {
      margin: '0px 10px',
    },
  },

  buttonInvest: {
    color: '#ff5001',
    border: '2px solid #ff5001',
    borderRadius: 15,
    width: '90%',
    fontSize: '1.2rem',

    [breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1rem',
    },
  },

  /*How To Play section */

  howToPlayRoot: {
    backgroundImage: `url('${BackgroundGeometry}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    paddingBottom: 0,
    paddingTop: 40,
    height: 'auto',

    [breakpoints.down('md')]: {
      backgroundPosition: 'center center',
    },

    [breakpoints.down('sm')]: {
      backgroundRepeat: 'repeat',
      paddingLef: 20,
    },
  },

  howToPlayTitles: {
    position: 'relative',
    fontWeight: 'bolder',

    [breakpoints.down('sm')]: {
      paddingLeft: 10,
    },

    '& span': {
      color: '#ff5001',
    },

    '&:first-child': {
      '&::after': {
        content: '""',
        width: '20%',
        height: 3,
        display: 'block',
        background: '#ff5001',
      },
    },

    '&:nth-child(3)': {
      marginTop: 250,

      [breakpoints.down('md')]: {
        fontSize: '2rem',
        marginTop: '90%',
      },

      [breakpoints.down('sm')]: {
        marginTop: '25%',
      },

      [breakpoints.down('xs')]: {
        marginTop: '110%',
      },

      [breakpoints.between(0, 321)]: {
        marginTop: '150%',
      },
    },
  },
  howToPlayList: {
    paddingBottom: 100,
  },

  howToPlayListItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    '&:last-child': {},
  },

  listItemIndex: {
    backgroundColor: '#ff5001',
    fontWeight: 'bolder',
    color: '#fff',
    padding: '0px 5px',
    borderRadius: 5,
    marginRight: 20,
  },

  howToPlayButtonPlayToday: {
    color: '#ff5001',
    border: '2px solid #ff5001',
    borderRadius: 10,
    padding: '5px 40px',
    marginTop: '45%',
    marginLeft: '5%',
    fontSize: '1.5rem',

    [breakpoints.between(1500, 1700)]: {
      marginTop: '30%',
    },

    [breakpoints.down('md')]: {
      marginTop: '80%',
    },

    [breakpoints.down('sm')]: {
      marginTop: '25%',
    },

    [breakpoints.down('xs')]: {
      marginTop: '95%',
    },

    ['@media (max-height:570px)']: {
      marginTop: 450,
    },
  },

  howToPlayPriceCard: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& h1': {
      color: '#ff5001',
      fontWeight: 'bolder',

      [breakpoints.down('sm')]: {
        fontSize: '4.5rem',
      },
    },

    '& span': {
      fontWeight: 800,
      fontSize: '1.3rem',
      paddingRight: 200,

      [breakpoints.down('sm')]: {
        fontSize: '1rem',
        paddingRight: 100,
      },
    },
  },

  phonesImgContainer: {
    '& img': {
      width: '120%',
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },

  /**When win andinitio section */

  whenWinAndinitoRoot: {
    paddingTop: 100,
    paddingBottom: 100,
    backgroundImage: `url('${BackgroundShow}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },

  whenWinAndinitoContainer: {
    color: '#fff',
    position: 'relative',
    textAlign: 'center',

    '&::before': {
      bottom: '100%',
      content: '""',
      display: 'block',
      height: 60,
      width: 2,
      left: '50%',
      position: 'absolute',
      background: '#fff',
    },
  },

  whenWinAndinitoContainerCarousel: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  whenWinAndinitoContainerCarouselNotice: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  whenWinAndinitocarouselCards: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
  },

  carouselPrevEl: {
    marginRight: 10,
    cursor: 'pointer',
    transform: 'rotate(-180deg)',
    color: '#fff',
    fontSize: '3rem',
    transition: '.3s all ease',

    '&:hover': {
      color: '#ff5001',
    },

    [breakpoints.down('sm')]: {
      marginLeft: '5%',
      fontSize: '2rem',
    },

    [breakpoints.between(0, 361)]: {
      marginLeft: 0,
    },
  },
  carouselNextEl: {
    cursor: 'pointer',
    color: '#fff',
    fontSize: '3rem',
    transition: '.3s all ease',

    '&:hover': {
      color: '#ff5001',
    },

    [breakpoints.down('sm')]: {
      marginRight: '10%',
      fontSize: '2em',
    },

    [breakpoints.between(0, 361)]: {
      marginRight: 0,
    },
  },

  buttonSeeMoreContainer: {
    margin: '0px 30px',
    [breakpoints.down('sm')]: {
      margin: '0px 10px',
    },
  },

  buttonSeeMore: {
    color: '#fff',
    border: '2px solid #fff',
    borderRadius: 15,
    width: '90%',
    fontSize: '1.2rem',

    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },

  /*Know your ticket*/

  KnowyourTicketRoot: {
    paddingBottom: 100,
    backgroundImage: `url('${KnowyourTicket}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },

  /*Winning Card section */

  winningCardsRoot: {
    paddingBottom: 100,
    backgroundImage: `url('${BackgroundConfetty}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },

  winningCardsContainer: {
    color: '#fff',
    textAlign: 'center',
    paddingTop: '45px',

    [breakpoints.down('sm')]: {
      padding: '0px 15px',
    },
  },

  carousel3Dcontainer: {
    marginRight: 30,
    [breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },

  /**Winner List Table */

  winnersTableTitle: {
    color: '#001941',
    backgroundColor: '#fff',
    borderRadius: '10px 10px 0px 0px',

    '& span': {
      fontWeight: 300,
    },

    [breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },

  winnersTableBody: {
    border: '1px solid #fff',
    borderRadius: '0px 0px 15px 15px',
    backgroundColor: 'rgba(0,25,66, .6)',
    position: 'relative',

    '& tr': {
      '& td': {
        color: '#fff',
        overflow: 'hidden',
        padding: '12px 10px',
        maxWidth: 250,
        textTransform: 'capitalize',
      },

      '&:last-child': {
        '& td': {
          borderBottom: 'none',
        },
      },
    },
  },

  tablePagination: {
    border: 'none',
    position: 'relative',

    '& div.tablePaginationContainer': {
      display: 'flex',
      justifyContent: 'space-around',
      marginLeft: '40%',
      width: '100%',
      marginTop: 0,
      marginBottom: 30,
    },
  },

  disablePropertys: {
    display: 'none',
    border: 'none',
  },

  /**Events Recents Root*/
  eventsRecentsRoot: {
    marginTop: 50,
    paddingBottom: 50,
    backgroundImage: `url('${BackgroundGeometry2}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',

    [breakpoints.down('sm')]: {
      backgroundRepeat: 'repeat',
      backgroundPosition: 'center center',
      padding: '0px 15px 50px 15px',
    },
  },

  eventsRecentsTitles: {
    fontWeight: 'bolder',
    color: '#ff5001',

    '& span': {
      color: '#000',
    },

    '&::after': {
      backgroundColor: '#ff5001',
      content: "''",
      display: 'block',
      width: '15%',
      height: 3,
    },

    [breakpoints.down('md')]: {
      marginLeft: 50,
    },

    [breakpoints.down('sm')]: {
      marginLeft: 0,
      fontSize: '2rem',
    },
  },

  eventsRecentsNotice: {
    height: 534,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  eventsRecentsNoticeSmall: {
    maxWidth: '240px',
    height: '595px',
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  eventsRecentsNoticeMedia: {
    height: 290,
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    color: '#fff',
    padding: '20px 20px',

    '&:first-child': {
      height: 260,
    },
  },

  buttonReadMore: {
    color: '#ff5001',
    border: '2px solid #ff5001',
    borderRadius: 10,
    padding: '5px 20px',
    marginTop: 'auto',
  },

  newsRecents: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,

    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },

    '& svg': {
      color: '#ff5001',
      fontSize: '1.2rem',
      marginTop: 5,
      marginRight: 10,
    },
  },

  eventRecentsVideo: {
    width: '100%',
    height: '400px',
  },

  eventsRecentsTags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',

    '& span': {
      marginTop: 10,
      marginRight: 10,
      backgroundColor: '#f4f6fe',
      color: '#485afc',
      padding: '5px 20px',
      fontWeight: 'bolder',

      [breakpoints.down('sm')]: {
        marginRight: 5,
        padding: '5px 15px',
      },
    },
  },

  /**Where Get Andinito Section */

  whereGetAndinitoRoot: {
    height: 'auto',
  },

  whereGetAndinitoContainer: {
    padding: '100px 100px 50px 150px',
    backgroundImage: `url('${BackgroundOrange}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    color: '#fff',
    textAlign: 'center',

    [breakpoints.down('md')]: {
      padding: '20px 0px 20px 10px',
      textAlign: 'center',
    },

    [breakpoints.down('sm')]: {
      padding: '20px 0px 20px 10px',
      textAlign: 'center',
      maxHeight: '70vh',
    },

    '& h1': {
      fontWeight: 'bolder',

      [breakpoints.down('md')]: {
        fontSize: '2rem',
      },

      [breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },

    '& button': {
      backgroundColor: '#fff',
      color: '#ff5001',
      fontSize: '1.2rem',
      padding: '5px 60px',
      borderRadius: 10,

      '&:hover': {
        backgroundColor: '#fff',
        color: '#ff5001',
        fontSize: '1.2rem',
        padding: '5px 60px',
        borderRadius: 10,
      },
    },
  },

  whereGetAndinitoImgContainer: {
    '& img': {
      width: '502px',
    },

    [breakpoints.down('sm')]: {
      marginLeft: '10%',
      '& img': {
        width: '100%',
      },
    },
  },
});
